import antdDE from 'antd/es/locale/de_DE';
import antdPicker from 'antd/es/date-picker/locale/de_DE';
import appLocaleData from 'react-intl/locale-data/de';
import deMessages from '../locales/de_DE.json';

const DeLang = {
  messages: {
    ...deMessages
  },
  antd: antdDE,
  locale: 'de-DE',
  data: appLocaleData,
  antdPicker: antdPicker
};
export default DeLang;
